import React, { useState, createRef } from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import './add.css'
import { getFunction, getUploadStorage } from '../model/firebase';

// const upvote = firebase.app().functions('europe-west1').httpsCallable('upvote');

const AddStonePage = () => {
    const [imageSrc, setImageSrc] = useState<string | ArrayBuffer>('http://placehold.it/180');

    const [formData, setFormData] = useState<{
        image?: File,
        psc?: string,
        name?: string,
        story?: string
    }>({
        image: null,
        psc: null,
        name: null,
        story: null
    });

    const imageSet = (event: React.ChangeEvent<HTMLInputElement>): File => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                setImageSrc(event.target.result)
            };
            reader.readAsDataURL(file);
        }
        return file;
    };
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const target = event.target;
        const value = target.name === 'image' ? imageSet(event) : target.value;
        // const value = target.value;
        const name = target.name;

        setFormData({
            ...formData,
            [name]: value
        });
    };
    const formSubmit = (event: React.FormEvent<EventTarget>) => {
        event.preventDefault()
        getUploadStorage()
            .then(upload => upload.child(formData.image.name))
            .then(upload => upload.put(formData.image))
            .then(uploadTask => {
                const addStone = getFunction('addStone');
                return addStone({...formData, image: formData.image.name})
            })
            .then((resp) => console.log('Resp', resp))
            .catch(e => console.error('Upload error', e))
    };

    let formValid = formData.psc && formData.image;

    return (
        <Layout>
            <SEO title="Přidat kamínek" />
            <h2>Přidat kamínek</h2>
            <p>Po přidání kamínku bude vygenerovyný kód vašeho kamínku.</p>
            <form id='stone' onSubmit={formSubmit}>
                <label>PSČ*: </label>
                <input type="text" name="psc" onChange={handleInputChange} />

                <label>Kamínek*: </label>
                {/* <img src={formData.imageSrc}
                    alt="your image"
                    height='180'
                    width='auto'
                    style={{
                        display: 'block',
                        marginBottom: '0'
                    }}/> */}
                <div style={{
                    backgroundImage: 'url("' + imageSrc + '")',
                    width: '180px',
                    height: '180px',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover'
                }}></div>
                <input type="file" name="image" accept="image/x-png,image/gif,image/jpeg,image/heic" onChange={handleInputChange} />

                <label>Název: </label>
                <input type="text" name="name" onChange={handleInputChange} />

                <label>Příběh: </label>
                <textarea name="story" form="stone"></textarea>

                <input type="submit" value="Přidat" disabled={!formValid} />
                <p style={{
                    color: 'gray',
                    fontSize: '70%'
                }}>* PSČ a fotka kamínku jsou povinné položky.</p>
            </form>
        </Layout>
    );
};

export default AddStonePage;